.App {
  text-align: center;
  width: 100vw;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 20vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.link {
  color: white;
  text-decoration: none;
}

/* .link.active {
  background-color: #3498db;
} */

/************* Ant design global overwrites  *************/

.ant-pro-page-container-children-content {
  margin: 12px 12px 0 !important;
}

.ant-page-header {
  padding: 8px 12px;
}
