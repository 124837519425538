@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(./fonts/OpenSans-Regular.ttf) format('truetype');
}

body {
  /* Prevent swing left and right */
  overflow: hidden;
  margin: 0;
  position: fixed;
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', -apple-system,
    BlinkMacSystemFont, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
